<template src="./OfferThumbnail.html">

</template>

<script>
const {DateTime} = require("luxon");

export default {
  name: "OfferThumbnail",
  props:{
    data:{
      type: Object
    },
    applicant:{
      type: Boolean,
      defaul: false
    }
  },
  filters:{
    dateFormat: function (value){
      return DateTime.fromISO(value).toFormat('dd LLLL yyyy ', { locale: "es" })
    }
  }
}
</script>

<style scoped lang="scss" src="./OfferThumbnail.scss">

</style>
