<template src="./ArrobaMedellinHorizontalCardIdi.html"></template>

<script>
export default {
  name: 'arroba-medellin-horizontal-card-idi',
  props: {
    img: {type: String},
    imgDescription: {type: String},
    title: {type: String},
    subtitle: {type: [String, Object]},
    body: {type: String, required: false},
    typeCard: {type: Object},
    buttonText: {type: String},
    url: {type: String, default: ""},
  },
  methods: {
    pushButton() {
      this.$emit('horizontalCardClickButton');
    },
    openCourseUrl(url) {
      if (url.length) {
        window.open(url, '_blank');
         this.$router.go(0)
      }
    }
  },
  computed: {
    disableButton() {
      return this.url.length < 1;
    }
  }
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinHorizontalCardIdi.scss"></style>
