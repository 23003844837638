<template src="./EmployerOfferThumbnail.html">

</template>

<script>
import currency from '../../filters/currency';
const {DateTime} = require("luxon");

export default {
  name: "OfferThumbnail",
  props:{
    data:{
      type: Object
    },
    currency
  },
  filters:{
    dateFormat: function (value){
      return DateTime.fromISO(value).toFormat('dd LLLL yyyy ', { locale: "es" })
    }
  }
}
</script>

<style scoped lang="scss" src="./EmployerOfferThumbnail.scss">

</style>
